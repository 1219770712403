import React, { ReactNode } from 'react'
import size from 'lodash/size'
import classNames from 'classnames'
import Text, { TextProps } from 'Components/texts/Text'
import SvgIcon from 'Components/SvgIcon/SvgIcon'
import Check from 'Assets/check.inline.svg'
import { EventType } from 'Typings/common'
import Row from 'Components/Row/Row'
import styles from './Checkbox.module.scss'

export type CheckboxProps = {
  className?: string
  disabled?: boolean
  value?: boolean
  label?: string | ReactNode
  renderLabel?: () => ReactNode
  renderAfterLabel?: () => ReactNode
  renderAtEnd?: () => ReactNode
  onChange?: (value: boolean) => void
  noWrap?: boolean
  labelProps?: TextProps
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    className = '',
    disabled = false,
    value = false,
    label,
    renderLabel,
    renderAfterLabel,
    renderAtEnd,
    onChange,
    noWrap = false,
    labelProps = {},
    ...other
  } = props

  const onClick = (event: EventType) => {
    event?.stopPropagation()
    !disabled && onChange?.(!value)
  }

  const withLabel = size(label) > 0 || Boolean(renderLabel)

  return (
    <Row
      vertical="top"
      onClick={onClick}
      className={classNames(
        styles.root,
        className,
        disabled ? styles.disabled : styles.enabled,
        value ? styles.checked : styles.unchecked,
        { [styles.noWrap]: noWrap },
      )}
      {...other}
    >
      <div className={styles.box}>
        {value ? <SvgIcon Icon={Check} className={styles.icon} /> : null}
      </div>
      {withLabel && (
        <Text variant="small" className={styles.label} {...labelProps}>
          {renderLabel?.() ?? label}
          {renderAfterLabel?.()}
        </Text>
      )}
      {renderAtEnd?.()}
    </Row>
  )
}
