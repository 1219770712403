import React, { ElementType } from 'react'
import classNames from 'classnames'
import Text, { TextVariants } from 'Components/texts/Text'
import { fadeUp } from 'Utils/animations'
import styles from './Headings.module.scss'

export type HeadingsProps = {
  className?: string
  descriptionClassName?: string
  headingClassName?: string
  data: {
    heading?: string
    description?: string
  }
  titleVariant?: TextVariants
  HeaderComponent?: ElementType
  center?: boolean
  isInvert?: boolean
}

const Headings = (props: HeadingsProps) => {
  const {
    className = '',
    headingClassName = '',
    descriptionClassName = '',
    data,
    titleVariant = 'heading',
    HeaderComponent = 'h2',
    center = false,
    isInvert = false,
  } = props

  const { heading, description } = data

  return (
    <div
      className={classNames([styles.root, className, { [styles.isInvert]: isInvert }])}
    >
      <Text
        className={classNames([styles.heading, headingClassName])}
        Component={HeaderComponent}
        variant={titleVariant}
        center={center}
        {...fadeUp()}
      >
        {heading || ''}
      </Text>
      {Boolean(description) && (
        <Text
          className={classNames([styles.description, descriptionClassName])}
          variant="sm"
          center={center}
          {...fadeUp({ index: 1 })}
        >
          {description}
        </Text>
      )}
    </div>
  )
}

export default Headings
