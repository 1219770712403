import React from 'react'
import classNames from 'classnames'
import { useContactForm } from 'Utils/common'
import Row from 'Components/Row/Row'
import Input, { INPUT_TYPES } from 'Components/inputs/Input/Input'
import { fadeUp } from 'Utils/animations'
import Button from 'Components/touchables/Button'
import Headings from 'Components/Headings/Headings'
import { Checkbox } from 'Components/Checkbox/Checkbox'
import Touchable from 'Components/touchables/Touchable'
import styles from './ContactForm.module.scss'
import { PATHS } from 'Utils/routing'

const LABEL_DIVIDER = '|||' // Как в файлах translation

export type ContactFormProps = {
  className?: string
  withCheckbox?: boolean
  onSubmit?: () => void
}

export const ContactForm = (props: ContactFormProps) => {
  const { className = '', withCheckbox = true, onSubmit } = props

  const {
    t,
    isSmallDevice,
    isMediumDevice,
    form,
    formSubmit,
    onChangeName,
    onChangeContact,
    onChangeText,
    isButtonDisabled,
    name,
    contact,
    text,
    isFetchingForm,
    isChecked,
    onCheck,
  } = useContactForm({ withCheckbox, onSubmit })

  const [main, link] = t('order.section.checkbox').split(LABEL_DIVIDER)

  const label = (
    <>
      {main}
      <Touchable className={styles.link} link={PATHS.policy}>
        {link}
      </Touchable>
    </>
  )

  return (
    <div className={classNames([styles.root, className])}>
      <Headings
        data={t('order.section', { returnObjects: true })}
        headingClassName={styles.heading}
      />
      <Row className={styles.fields}>
        <div className={styles.left}>
          <Input
            className={styles.input}
            label={form.name}
            value={name}
            onChange={onChangeName}
            {...fadeUp({ index: 2 })}
          />
          <Input
            className={styles.input}
            label={form.contact}
            value={contact}
            onChange={onChangeContact}
            {...fadeUp({ index: 3 })}
          />
        </div>
        <Input
          className={classNames([styles.input, styles.textarea])}
          type={INPUT_TYPES.textArea}
          label={form.text}
          value={text}
          onChange={onChangeText}
          rows={isSmallDevice ? 12 : 6}
          {...fadeUp({ index: isMediumDevice ? 4 : 2 })}
        />
      </Row>
      <Row className={styles.footer}>
        {withCheckbox && (
          <div className={styles.checkbox} {...fadeUp({ index: isMediumDevice ? 5 : 4 })}>
            <Checkbox label={label} value={isChecked} onChange={onCheck} />
          </div>
        )}
        <div className={styles.wrapper} {...fadeUp({ index: isMediumDevice ? 6 : 5 })}>
          <Button
            className={styles.button}
            isFetching={isFetchingForm}
            title={form.submit}
            disabled={isButtonDisabled}
            onClick={formSubmit}
          />
        </div>
      </Row>
    </div>
  )
}
