import React from 'react'
import classNames from 'classnames'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import { ContactForm } from 'Components/Forms/ContactForm/ContactForm'
import styles from './FormSection.module.scss'

export type FormSectionProps = {
  className?: string
  contentClassName?: string
}

export const FormSection = (props: FormSectionProps) => {
  const { className = '', contentClassName = '' } = props

  return (
    <BaseContainer
      className={classNames([styles.root, className])}
      contentClassName={classNames([styles.content, contentClassName])}
      Component="section"
    >
      <ContactForm />
    </BaseContainer>
  )
}
