import React, { MutableRefObject, useCallback, useRef } from 'react'
import { get } from 'lodash'
import { parallaxTranslate, useScrollParallax } from 'Utils/parallax'
import { MQ, useMediaMatches } from 'Utils/mq'
import Phone1 from 'Assets/phone-1.inline.png'
import Phone2 from 'Assets/phone-2.inline.png'
import Tablet from 'Assets/tablet.inline.png'
import Tablet1 from 'Assets/tablet-1.inline.png'
import Tablet2 from 'Assets/tablet-2.inline.png'
import Laptop from 'Assets/laptop.inline.png'
import styles from './Illustrations.module.scss'
import classNames from 'classnames'

export const getIllustrationsClassName = (
  classes: Record<string, string>,
  type: string,
): string => {
  return get(
    {
      [ILLUSTRATION_TYPES.web]: classes.isWeb,
      [ILLUSTRATION_TYPES.design]: classes.isDesign,
      [ILLUSTRATION_TYPES.tablet]: classes.isTablet,
      [ILLUSTRATION_TYPES.phones]: classes.isPhones,
      [ILLUSTRATION_TYPES.laptop]: classes.isLaptop,
    },
    type,
  )
}

export const ILLUSTRATION_TYPES = {
  tablet: 'Tablet',
  phones: 'Phones',
  laptop: 'Laptop',
  web: 'Web',
  design: 'Design',
}

export type IllustrationsProps = {
  container: MutableRefObject<HTMLElement | null>
  type: string
  isCustom?: boolean
}

export const Illustrations = (props: IllustrationsProps) => {
  const { container, type, isCustom = false } = props

  const phone1 = useRef<HTMLImageElement>(null)
  const phone2 = useRef<HTMLImageElement>(null)
  const tablet = useRef<HTMLImageElement>(null)
  const laptop = useRef<HTMLImageElement>(null)
  const web = useRef<HTMLImageElement>(null)
  const design = useRef<HTMLImageElement>(null)

  const isTabletScreen = useMediaMatches({ maxWidth: MQ.breakpoints.tablet })
  const offsetStart = isTabletScreen ? 0 : 50

  const onParallaxScroll = useCallback(
    (value: number) => {
      if (type === ILLUSTRATION_TYPES.phones) {
        const phonesOffsetMultiplier = 0.8

        parallaxTranslate(phone1, {
          value: value + (isCustom ? 160 : 0),
          skew: value / 50,
        })

        parallaxTranslate(phone2, {
          value: (value - (isCustom ? 125 : 0)) * phonesOffsetMultiplier,
          skew: -value / 50,
        })
      } else if (type === ILLUSTRATION_TYPES.tablet) {
        parallaxTranslate(tablet, { value, skew: value / 20 })
      } else if (type === ILLUSTRATION_TYPES.laptop) {
        parallaxTranslate(laptop, { value, rotate: -value / 30 })
      } else if (type === ILLUSTRATION_TYPES.web) {
        parallaxTranslate(web, { value, skew: value / 20 })
      } else if (type === ILLUSTRATION_TYPES.design) {
        parallaxTranslate(design, { value, skew: value / 20 })
      }
    },
    [isCustom],
  )

  useScrollParallax(container, onParallaxScroll, offsetStart)

  if (type === ILLUSTRATION_TYPES.phones) {
    return (
      <div className={classNames([styles.phones, { [styles.custom]: isCustom }])}>
        <img ref={phone1} className={styles.phone1} src={Phone1} alt="" />
        <img ref={phone2} className={styles.phone2} src={Phone2} alt="" />
      </div>
    )
  }

  if (type === ILLUSTRATION_TYPES.tablet) {
    return <img ref={tablet} className={styles.tablet} src={Tablet} alt="" />
  }

  if (type === ILLUSTRATION_TYPES.laptop) {
    return <img ref={laptop} className={styles.laptop} src={Laptop} alt="" />
  }

  if (type === ILLUSTRATION_TYPES.web) {
    return <img ref={web} className={styles.web} src={Tablet1} alt="" />
  }

  if (type === ILLUSTRATION_TYPES.design) {
    return <img ref={design} className={styles.design} src={Tablet2} alt="" />
  }

  return null
}
